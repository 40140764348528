import {
    IBotAddRequest, IBotEditRequest, IBotsGetResponse,
    IBotsInterface,
    IWalletsGetRequest,
    IWalletsGetResponse,
    IWalletsUpdateRequest
} from "./models";
import {apiClient} from "../serverConstants";

class BotsService implements IBotsInterface {
    async addBot(data: IBotAddRequest) {
        const response = await apiClient.post('/bots', data);
        return response.data;
    }
    async getBots(data: IWalletsGetRequest) {
        const response = await apiClient.get('/bots', {params: data});
        return response.data as IBotsGetResponse;
    }
    async editBot(data: IBotEditRequest) {
        const response = await apiClient.patch('/bots', data);
        return response.data;
    }
    async removeBot(data: IWalletsUpdateRequest) {
        const response = await apiClient.delete('/bots', {data});
        return response.data;
    }

    async startBot(data: IWalletsUpdateRequest) {
        const response = await apiClient.post('/bots/start', data);
        return response.data as IWalletsGetResponse;
    }
    async stopBot(data: IWalletsUpdateRequest) {
        const response = await apiClient.post('/bots/stop', data);
        return response.data as IWalletsGetResponse;
    }
}

export default new BotsService();