import React, {useContext} from "react";
import {
    FlexRow,
    TopValueWrapper,
    ValueWrapper
} from "../global/global";
import BlockTitleWithButton from "../BlockTitleWithButton";
import {IBot, IPool, IWalletsUpdateRequest} from "../../api/service/models";
import GlobalModalStore from "../../storage/GlobalModalStore/GlobalModalStore";
import {useMutation, useQueryClient} from "react-query";
import BotsApi from "../../api/service/BotsApi";
import BotBlock from "./components/BotBlock";
import styled from "styled-components";


interface PoolsComponentProps {
    pools: IPool[];
    bots: IBot[];
    openMenu: () => void;
}

const BotsWrapper = styled(FlexRow)`
  gap: 16px;
  flex-wrap: wrap;
  justify-content: start;
`;

const BotsComponent: React.FC<PoolsComponentProps> = ({bots, openMenu, pools}) => {

    const GlobalModalStorage = useContext(GlobalModalStore);
    const queryClient = useQueryClient();

    const removeBotMutation = useMutation((data: IWalletsUpdateRequest) => BotsApi.removeBot(data), {
        onError: error => {
            GlobalModalStorage.makeVisible(false, "Бот не удален");
        },
        onSuccess: data => {
            GlobalModalStorage.makeVisible(true, "Бот удален");
            queryClient.invalidateQueries('get_bots');
        },
    });
    const startBotMutation = useMutation((data: IWalletsUpdateRequest) => BotsApi.startBot(data), {
        onError: error => {
            GlobalModalStorage.makeVisible(false, "Бот не запущен");
        },
        onSuccess: data => {
            GlobalModalStorage.makeVisible(true, "Бот запущен");
            queryClient.invalidateQueries('get_bots');
        },
    });
    const stopBotMutation = useMutation((data: IWalletsUpdateRequest) => BotsApi.stopBot(data), {
        onError: error => {
            GlobalModalStorage.makeVisible(false, "Бот не остановлен");
        },
        onSuccess: data => {
            GlobalModalStorage.makeVisible(true, "Бот остановлен");
            queryClient.invalidateQueries('get_bots');
        },
    });

    const removeBot = (id: number) => {
        removeBotMutation.mutate({id: id});
    };
    const toggleStartBot = (id: number) => {
        if (bots.find(bot => bot.id === id).status === "Stopped") {
            startBotMutation.mutate({id: id});
        } else stopBotMutation.mutate({id: id});
    };

    const isStartOrDeleteLoading = removeBotMutation.isLoading || startBotMutation.isLoading || stopBotMutation.isLoading;

    return (
        <TopValueWrapper style={{width: "100%"}}>
            <ValueWrapper style={{height: "auto", width: "100%", alignItems: "start"}}>
                <BlockTitleWithButton title="Bots" actionTitle="Create bot" action={openMenu}/>
                <BotsWrapper>
                    {bots && bots.length > 0 && bots.map((bot: IBot) => <BotBlock bot={bot}
                                                                                  pools={pools}
                                                                                  key={bot.id}
                                                                                  toggleStartBot={toggleStartBot}
                                                                                  isStartOrDeleteLoading={isStartOrDeleteLoading}
                                                                                  removeBot={removeBot}/>)}
                </BotsWrapper>
            </ValueWrapper>
        </TopValueWrapper>
    );
};


export default BotsComponent;