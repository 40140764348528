import React, {ComponentPropsWithoutRef, useState} from "react";
import styled from "styled-components";
import {Label, StyledInput} from "./global";
import {colors} from "../../config/colors";

type ValidationInputComponentProps = {
    value?: string;
    isError?: any;
    label?: string;
    ref?: any;
}

export const ValidationLabel = styled.label<{ isFocused?: any }>`
  display: ${props => props.isFocused ? "block" : "none"};
  font-size: 14px;
  position: absolute;
  margin-top: 10px;
  transition: all 1s;
  color: ${colors.gray600};
  width: fit-content;
`;

export const CustomStyledInput = styled(StyledInput)`
  position: relative;
  font-family: sans-serif;
  width: 100%;
`;
export const ValidationInputComponent: React.FC<ValidationInputComponentProps & ComponentPropsWithoutRef<'input'>> = (props) => {

    const {value, isError, label, ref, ...other} = props;

    const [isFocused, setIsFocused] = useState(false);

    return (
        <>
            <CustomStyledInput
                {...other}
                ref={ref}
                value={value}
                placeholder={isFocused ? "" : label}
                onChange={other.onChange}
                isFailed={isError}
                onBlur={(e) => {
                    setIsFocused(false);
                    if (!!other.onBlur) {
                        other.onBlur(e);
                    }
                }}
                onFocus={(e) => {
                    setIsFocused(true);
                    if (!!other.onFocus) {
                        other.onFocus(e);
                    }
                }}
            />
            <ValidationLabel isFocused={isFocused || value}>{label}</ValidationLabel>
        </>
    );
};
