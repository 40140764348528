export type IAuthSigninRequest = {
    login: string,
    password: string
}

export type IWalletsAddWalletRequest = {
    walletPrivateKey: string
}
export type IWalletsAddPoolRequest = {
    name: string
    walletIds: number[]
}

export type IWalletsEditPoolRequest = {
    walletPoolId: number
    walletIds: number[]
}

export type IAddWalletToPoolRequest = {
    walletId: number,
    walletPoolId: number
}

export type IWalletsGetRequest = {
    pageSize: number
    pageNumber: number
}
export type IWalletsUpdateRequest = {
    id: number
}
export type IGetStatsRequest = {
    botId: number
}
export type IGetBotHistoryRequest = {
    botId: number
    pageSize: number
    pageNumber: number
}
export type IGetDailyHistoryRequest = {
    botId: number
    from: string
    to: string
}
export type IGetStatsDailyGeneralResponse = {
    mainTokenSellVolume: number,
    quoteTokenSellVolume: number,
    totalVolumeInUSD: number
}
export type IGetStatsDailyTokensResponse = {
    mainToken: IERC20Token,
    quoteToken: IERC20Token,
    todayMainTokenBalance: string,
    todayQuoteTokenBalance: string,
    yesterdayMainTokenBalance: string,
    yesterdayQuoteTokenBalance: string
}
export type IGetStatsTokenPriceResponse = {
    mainToken: IERC20Token,
    quoteToken: IERC20Token,
    mainTokenPriceInQuoteToken: string,
    quoteTokenPriceInMainToken: string,
    mainTokenPriceInUSD: 0,
    quoteTokenPriceInUSD: 0
}
export type IGetBotHistoryResponse = {
    items: ICycleItem[],
    pageNumber: number,
    totalPages: number,
    totalCount: number,
    hasPreviousPage: boolean,
    hasNextPage: boolean
}
export type IGetBotDailyHistory = {
    date: string,
    network: INetworkType,
    mainToken: IWeb3TokenMini,
    quoteToken: IWeb3TokenMini,
    totalDeals: number,
    reverseDeals: number,
    forwardDeals: number,
    forwardVolumeInMainToken: IDecimalsAndAmount,
    reverseVolumeInMainToken: IDecimalsAndAmount
}
export type ICycleItem = {
    cycle: number,
    network: INetworkType,
    mainToken: IWeb3TokenMini,
    quoteToken: IWeb3TokenMini,
    totalDeals: number,
    forwardDeals: number,
    reverseDeals: number,
    forwardVolumeInMainToken: IDecimalsAndAmount,
    reverseVolumeInMainToken: IDecimalsAndAmount
}
export type IERC20Token = {
    address: string,
    network: INetworkType,
    price: number,
    symbol: string,
    decimals: number
}
export type IWallet = {
    id: number,
    address: string,
    gas: INetworkGas
}
export type IPool = {
    id: number,
    name: string,
    wallets: IWallet[],
    ownerId: string
}
export type IWalletsGetResponse = {
    items: IWallet[],
    pageNumber: number,
    totalPages: number,
    totalCount: number,
    hasPreviousPage: boolean,
    hasNextPage: boolean
}
export type IBot = {
    id: number,
    name: string,
    status: IBotStatus,
    strategy: IStrategy,
    swapService: ISwapService,
    network: INetworkType,
    poolFee: IPoolFee | number,
    mainToken: IWeb3Token,
    quoteToken: IWeb3Token,
    walletPool: IPool
}
export type IWeb3Token = {
    address: string,
    network: INetworkType,
    price: number,
    symbol: string,
    decimals: number
}
export type IWeb3TokenMini = {
    address: string,
    symbol: string,
    decimals: number
}
export type IDecimalsAndAmount = {
    number: number,
    decimals: number
}
export type IBotsGetResponse = {
    items: IBot[],
    pageNumber: number,
    totalPages: number,
    totalCount: number,
    hasPreviousPage: boolean,
    hasNextPage: boolean
}

export type IWalletsGetPoolsResponse = {
    items: IPool[],
    pageNumber: number,
    totalPages: number,
    totalCount: number,
    hasPreviousPage: boolean,
    hasNextPage: boolean
}

export type IBotAddRequest = {
    name: string,
    network: INetworkType,
    mainTokenAddress: string,
    quoteTokenAddress: string,
    poolFee: IPoolFee,
    strategyType: IStrategyType,
    swapService: ISwapService,
    dealsPerDay: number,
    minDealVolumeInToken: string,
    maxDealVolumeInToken: string,
    minDayVolumeInToken: string,
    maxDayVolumeInToken: string,
    minSignificantDigits: number,
    maxSignificantDigits: number,
    walletPoolId: number
}
export type IBotEditRequest = {
    botId: number,
    name: string,
    network: INetworkType,
    mainTokenAddress: string,
    quoteTokenAddress: string,
    poolFee: IPoolFee,
    strategyType: IStrategyType,
    swapService: ISwapService,
    dealsPerDay: number,
    minDealVolumeInToken: string,
    maxDealVolumeInToken: string,
    minDayVolumeInToken: string,
    maxDayVolumeInToken: string,
    walletPoolId: number
}

export type IStrategy = {
    id: number,
    strategyType: IStrategyType,
    dealsPerDay: number,
    todayVolumeGoal: IDecimalsAndAmount,
    currentForwardDealsVolume: IDecimalsAndAmount,
    currentReverseDealsVolume: IDecimalsAndAmount,
    currentDealsCount: number,
    dayGoalsUpdatedOn: string,
    minDealVolumeInToken: string,
    maxDealVolumeInToken: string,
    minDayVolumeInToken: string,
    maxDayVolumeInToken: string,
    minSignificantDigits: number,
    maxSignificantDigits: number
}

export enum INetworkType {
    Unknown = 'Unknown',
    ETH = 'ETH',
    BSC = 'BSC',
    Polygon = 'Polygon',
    Arbitrum = 'Arbitrum',
    Base = 'Base',
    Optimism = 'Optimism'
}

export type INetworkGas = {
    ETH: string,
    BSC: string,
    Polygon: string,
    Arbitrum: string,
    Base: string,
    Optimism: string
}

export enum IBotStatus {
    Starting = 'Starting',
    Executing = 'Executing',
    Stopped = 'Stopped',
}

export enum IStrategyType {
    Buy = 'Buy',
    Sell = 'Sell',
    Volume = 'Volume'
}

export enum ISwapService {
    Uniswap = 'Uniswap',
    Pancake = 'Pancake'
}

export enum IPoolFee {
    P100 = 'P100',
    P500 = 'P500',
    P3000 = 'P3000',
    P10000 = 'P10000',
}

export type ITokens = {
    accessToken: string,
    refreshToken: string
}

export type IAuthInterface = {
    signin(data: IAuthSigninRequest)
    refreshToken(data: ITokens)
}

export type IWalletsInterface = {
    addWallet(data: IWalletsAddWalletRequest)
    getWallets(data: IWalletsGetRequest)
    removeWallet(data: IWalletsUpdateRequest)
    addPool(data: IWalletsAddPoolRequest)
    removePool(data: IWalletsUpdateRequest)
    getPools(data: IWalletsGetRequest)
}

export type IBotsInterface = {
    addBot(data: IBotAddRequest)
    getBots(data: IWalletsGetRequest)
    removeBot(data: IWalletsUpdateRequest)
}

export type IStatsInterface = {
    dailyGeneral(data: IGetStatsRequest)
    dailyTokens(data: IGetStatsRequest)
    dailyTokenPrice(data: IGetStatsRequest)
}