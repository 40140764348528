export const DEFAULT_NETWORKS = [
    {
        name: "Binance Smart Chain",
        symbol: "BNB",
        backendName: "BSC"
    },
    {
        name: "Ethereum",
        symbol: "ETH",
        backendName: "ETH"
    },
    {
        name: "Matic",
        symbol: "MATIC",
        backendName: "Polygon"
    },
    {
        name: "Arbitrum",
        symbol: "ARB",
        backendName: "Arbitrum"
    },
    {
        name: "Base",
        symbol: "BASE",
        backendName: "Base"
    },
    {
        name: "OP",
        symbol: "OP",
        backendName: "Optimism"
    },
]