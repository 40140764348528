import React from "react";
import styled from "styled-components";
import { FlexColumn } from "./global/global";
import StatsComponent from "./StatsComponent";

const TopValueWrapper = styled.div`
  display: flex;
  align-items: start;
  justify-content: start;
  flex-direction: row;
  margin-top: 22px;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
const TopValueColumn = styled(FlexColumn)`
  @media (max-width: 768px) {
    width: calc(100% - 32px);
  }
`;

interface StatisticsItem {
    value: string;
    title: string;
}

interface StatisticsPanelComponentProps {
    stats: StatisticsItem[][];
}

const StatisticsPanelComponent: React.FC<StatisticsPanelComponentProps> = ({stats}) => {

    return (
        <TopValueWrapper>
            {stats.map((statsArray) =>
                <TopValueColumn>
                    {statsArray.map((s) =>
                        <StatsComponent value={s.value} title={s.title}/>
                    )}
                </TopValueColumn>
            )}
        </TopValueWrapper>
    );
};


export default StatisticsPanelComponent;