import React, {useContext} from "react";
import {
    Table,
    TopValueWrapper,
    ValueWrapper
} from "../global/global";
import BlockTitleWithButton from "../BlockTitleWithButton";
import {IWallet, IWalletsUpdateRequest} from "../../api/service/models";
import WalletComponent from "./components/WalletComponent";
import {useMutation, useQueryClient} from "react-query";
import WalletsApi from "../../api/service/WalletsApi";
import GlobalModalStore from "../../storage/GlobalModalStore/GlobalModalStore";

interface WalletsComponentProps {
    wallets: IWallet[];
    openMenu: () => void;
}

const WalletsComponent: React.FC<WalletsComponentProps> = ({wallets, openMenu}) => {

    const GlobalModalStorage = useContext(GlobalModalStore);
    const queryClient = useQueryClient();

    const removeWalletMutation = useMutation((data: IWalletsUpdateRequest) => WalletsApi.removeWallet(data), {
        onError: error => {
            GlobalModalStorage.makeVisible(false, "Не удалено");
        },
        onSuccess: data => {
            GlobalModalStorage.makeVisible(true, "Успешно удалено");
            queryClient.invalidateQueries('get_wallets');
        },
    });

    const removeWalletFunction = (id: number) => {
        removeWalletMutation.mutate({id: id});
    };

    return (
        <TopValueWrapper style={{width: "100%"}}>
            <ValueWrapper style={{height: "auto", width: "100%", alignItems: "start"}}>
                <BlockTitleWithButton title="Wallets list" actionTitle="Add Wallet" action={openMenu}/>
                <Table>
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>Address</th>
                        <th>ETH Gas</th>
                        <th>BSC Gas</th>
                        <th>MATIC Gas</th>
                        <th>Base Gas</th>
                        <th>OP Gas</th>
                        <th>ARB Gas</th>
                        <th>Action</th>
                    </tr>
                    </thead>
                    {wallets && wallets.map((wallet: IWallet) => (
                        <WalletComponent address={wallet.address}
                                         id={wallet.id}
                                         gas={wallet.gas}
                                         key={wallet.id}
                                         removeWallet={removeWalletFunction}/>
                    ))}
                </Table>
            </ValueWrapper>
        </TopValueWrapper>
    );
};


export default WalletsComponent;