import styled from "styled-components";
import {StyledInput} from "../../global/global";
import {colors} from "../../../config/colors";

export const StyledInputModal = styled(StyledInput)`
  font-size: 14px;
  padding: 10px;
  width: 100%;
`;

export const ModalButton = styled.button`
  width: 100%;
  font-size: 16px;
  font-weight: 300;
  cursor: pointer;
  height: 44px;
  margin-top: 10px;
  background: ${colors.primary700};
  border-radius: 6px;
  color: #fff;
  border: none;

  &:hover {
    opacity: 0.9;
  }
`;