import {FC, ReactNode, useContext} from "react";
import {observer} from "mobx-react-lite";
import styled from "styled-components";
import MessageModal from "./components/modal/MessageModal";
import {FlexColumn} from "./components/global/global";
import GlobalModalStore from "./storage/GlobalModalStore/GlobalModalStore";
import { configurePersistable } from 'mobx-persist-store'
import {queryClient} from "./api/query-client";
import { QueryClientProvider } from 'react-query'

type IAppProviderProps = {
    children?: ReactNode
};

const ModalsColumn = styled(FlexColumn)`
  position: fixed;
  top: 8.56rem;
  right: 1rem;
  z-index: 100;
  row-gap: 1rem;
`

const AppProvider: FC<IAppProviderProps> = (props) => {
    const {children} = props;
    const GlobalModalStorage = useContext(GlobalModalStore);

    configurePersistable(
        {
            debugMode: true,
            expireIn: 86400000,
            removeOnExpiration: true,
            storage: window.localStorage,
            stringify: false,
        },
        { delay: 200, fireImmediately: false }
    )

    return (
        <QueryClientProvider client={queryClient}>
            <ModalsColumn>
                {GlobalModalStorage.windows.map((it) =>
                    <MessageModal {...it}/>
                )}
            </ModalsColumn>

            {children}
        </QueryClientProvider>
    );
};

export default observer(AppProvider)
