export const colors = {
    powderBlue: "#95B8D1",
    vistaBlue: "#3b72dc",
    gray: "#e8e8e8",
    greenSuccess: "#10D096",
    redFail: "#DB6F68",
    redInput: "rgba(224,31,25,.16)",
    gray0: "#161B21",
    gray400: "#BAC4D1",
    gray600: "#7D8FA9",
    gray800: "#3B4758",
    gray900: "#1D232C",
    grayM200: "#07090B",
    purple400: "#B142F5",
    primary600:  "#319DFF",
    primary700:  "#0077E4",
    danger600:  "#FF316A",
    warning600:  "#FFAA04",
    success500:  "#10D096"
}