import React from "react";
import {FlexRow} from "./global/global";
import styled from "styled-components";
import {colors} from "../config/colors";
import {truncate} from "../utils/truncate";

interface BlockProps {
    selected: boolean;
}

const Block = styled(FlexRow)<BlockProps>`
  justify-content: space-between;
  box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
  color: ${({selected}) => selected && "#fff"};
  font-size: 12px;
  border-radius: 6px;
  background: ${({selected}) => selected ? colors.vistaBlue : colors.gray800};
  cursor: pointer;
  padding: 0 8px;
  user-select: none;
  width: 380px;
  @media(max-width: 480px) {
    width: 100%;
  }
`;

interface WalletWithBalancesProps {
    id: number;
    address: string;
    selected: boolean;
    addWallet: (id: number) => void;
}

const WalletWithBalances: React.FC<WalletWithBalancesProps> = ({id, address, selected, addWallet}) => {

    return (
        <Block selected={selected} onClick={() => addWallet(id)}>
            <p>ID: {id}</p>
            <p>{truncate(address)}</p>
        </Block>
    );
};


export default WalletWithBalances;