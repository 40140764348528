import React from "react";
import {
    FlexColumn,
    Modal,
    ModalBlock,
    ModalTitle
} from "../global/global";
import styled from "styled-components";
import ModalHeaderComponent from "./components/ModalHeaderComponent";
import {ICycleItem, IGetBotDailyHistory} from "../../api/service/models";
import LineBarAreaComposedChart from "../global/LineBarAreaComposedChart/LineBarAreaComposedChart";
import StakedAreaChart from "../global/StackedAreaChart/StakedAreaChart";
import {getBalanceNumber} from "../../utils/convertNumberToEth";
import BigNumber from "bignumber.js";
import {colors} from "../../config/colors";
import format from "date-fns/format";

const ChartsPanel = styled(FlexColumn)`
  gap: 12px;
  width: 100%;
`;

interface BotChartsModalProps {
    cycles: ICycleItem[];
    openMenu: boolean;
    handleMenu: () => void;
    mainToken: string;
    dailyHistory: IGetBotDailyHistory[];
}

const BotChartsModal: React.FC<BotChartsModalProps> = ({
                                                           cycles,
                                                           openMenu,
                                                           handleMenu,
                                                           mainToken,
                                                           dailyHistory
                                                       }) => {

    const formattedCyclesData = cycles?.length > 0 && cycles.map((cycle) => {
        const forwardVol = getBalanceNumber(new BigNumber(cycle.forwardVolumeInMainToken.number), cycle.forwardVolumeInMainToken.decimals);
        const reverseVol = getBalanceNumber(new BigNumber(cycle.reverseVolumeInMainToken.number), cycle.reverseVolumeInMainToken.decimals);
        const total = forwardVol + reverseVol;
        return {
            forwardDeals: cycle.forwardDeals,
            reverseDeals: cycle.reverseDeals,
            totalDeals: cycle.totalDeals,
            forwardTokenVolume: parseFloat(forwardVol.toFixed(6)),
            reverseTokenVolume: parseFloat(reverseVol.toFixed(6)),
            totalVolume: parseFloat(total.toFixed(6)),
            cycle: cycle.cycle
        };
    }) || null;

    const formattedHistoryData = dailyHistory?.length > 0 && dailyHistory.map((day) => {
        const forwardVol = getBalanceNumber(new BigNumber(day.forwardVolumeInMainToken.number), day.forwardVolumeInMainToken.decimals);
        const reverseVol = getBalanceNumber(new BigNumber(day.reverseVolumeInMainToken.number), day.reverseVolumeInMainToken.decimals);
        const total = forwardVol + reverseVol;
        return {
            forwardDeals: day.forwardDeals,
            reverseDeals: day.reverseDeals,
            totalDeals: day.totalDeals,
            forwardTokenVolume: parseFloat(forwardVol.toFixed(6)),
            reverseTokenVolume: parseFloat(reverseVol.toFixed(6)),
            totalVolume: parseFloat(total.toFixed(6)),
            date: format(new Date(day.date), 'yyyy-MM-dd')
        };
    }) || null;

    const cycleChartAreas = [
        {dataKey: "forwardTokenVolume", color: colors.purple400},
        {dataKey: "reverseTokenVolume", color: "#82ca9d"},
        {dataKey: "totalVolume", color: "#ffc658"},
    ];
    const cycleChartBars = [
        {dataKey: "forwardDeals", color: colors.greenSuccess},
        {dataKey: "reverseDeals", color: colors.redFail},
    ];

    if (openMenu) return (
        <Modal>
            <ModalBlock>
                <ModalHeaderComponent title="Cycles stats" handleMenu={handleMenu}/>
                <ChartsPanel>
                    <ModalTitle style={{marginBottom: 16}}>Trading volume {mainToken} in cycle</ModalTitle>
                    <StakedAreaChart cycles={formattedCyclesData}
                                     dataKey="cycle"
                                     areas={cycleChartAreas}
                    />
                    <ModalTitle style={{marginBottom: 16, marginTop: 16}}>Amount of trades in a cycle</ModalTitle>
                    <LineBarAreaComposedChart cycles={formattedCyclesData}
                                              dataKey="totalDeals"
                                              xAxisKeys={['cycle']}
                                              bars={cycleChartBars}/>
                    <ModalTitle style={{marginBottom: 16}}>Trading volume {mainToken} daily</ModalTitle>
                    <StakedAreaChart cycles={formattedHistoryData}
                                     dataKey="date"
                                     areas={cycleChartAreas}
                    />
                    <ModalTitle style={{marginBottom: 16}}>Amount of trades per day</ModalTitle>
                    <LineBarAreaComposedChart cycles={formattedHistoryData}
                                              dataKey="totalDeals"
                                              xAxisKeys={['date']}
                                              bars={cycleChartBars}
                    />
                </ChartsPanel>
            </ModalBlock>
        </Modal>
    );
};


export default BotChartsModal;
