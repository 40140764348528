import {ethers} from "ethers";

export const isValidEthereumAddress = (address: string) => {
    // Ethereum address regex pattern
    const ethereumAddressPattern = /^(0x)?[0-9a-fA-F]{40}$/;
    return ethereumAddressPattern.test(address);
};

export const isValidPrivateKey = (privateKey: string) => {
    try {
        const wallet = new ethers.Wallet(privateKey);
        // If the wallet was successfully created, the private key is valid
        return wallet.privateKey !== '0x0000000000000000000000000000000000000000000000000000000000000000';
    } catch (error) {
        // An error occurred during wallet creation, indicating an invalid private key
        return false;
    }
}

export const addressFromPrivateKey = (privateKey: string) => {
    const wallet = new ethers.Wallet(privateKey);
    return wallet.address;
}
