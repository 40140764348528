import styled from "styled-components";
import {colors} from "../../config/colors";

export const FlexRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`;
export const FlexColumn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

interface FlexColumnStartProps {
    fz?: boolean;
}

export const FlexColumnStart = styled.div<FlexColumnStartProps>`
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  width: ${({fz}) => fz ? "100%" : "auto"};
`;
export const FlexColumnEnd = styled.div<FlexColumnStartProps>`
  display: flex;
  align-items: end;
  justify-content: center;
  flex-direction: column;
  width: ${({fz}) => fz ? "100%" : "auto"};
`;
export const FlexRowBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
`;

export const Title = styled.h2`
  text-align: left;
  width: 100%;
  margin: 0;
  margin-bottom: 24px;
  font-size: 22px;
`;

interface StyledInputProps {
    isFailed?: boolean;
    value?: string;
}

export const StyledInput = styled.input<StyledInputProps>`
  outline: none;
  font-size: 15px;
  padding: 18px 20px;
  padding-top: ${({value}) => value && "28px"};
  padding-bottom: ${({value}) => value && "8px"};
  margin: 0;
  border-radius: 8px;
  background: ${({isFailed}) => isFailed ? colors.redInput : colors.gray800};
  border: 1px solid ${({isFailed}) => isFailed ? colors.redInput : "rgba(0, 0, 0, 0)"} !important;
  width: 100%;
  color: ${colors.gray400};

  &:focus {
    background: ${colors.gray800};
    border: 1px solid ${({isFailed, value}) => isFailed ? colors.redInput : colors.gray800};
    padding-top: 28px;
    padding-bottom: 8px;
  }

  &:-webkit-autofill {
    border: 1px solid ${({isFailed, value}) => isFailed ? colors.redInput : colors.gray800} !important;
    -webkit-text-fill-color: ${colors.gray400} !important;
    -webkit-box-shadow: 0 0 0px 1000px ${colors.gray800} inset;
    -webkit-border-image: none;
  }
`;

export const TopValueWrapper = styled.div`
  display: flex;
  align-items: start;
  justify-content: start;
  flex-direction: row;
  margin-top: 22px;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const ValueWrapper = styled.div`
  padding: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 10px;
  height: 80px;
  width: 300px;
  @media (max-width: 768px) {
    width: 100%;
    overflow-x: auto;
  }
`;

export const TableText = styled.p`
  font-size: 14px;
  margin: 0;
`;


export const Modal = styled(FlexColumn)`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  justify-content: start;
  background: rgba(0, 0, 0, 0.8);
  z-index: 999;
  overflow-y: scroll;

  * {
    box-sizing: border-box;
  }
`;


export const ModalHeader = styled(FlexRowBetween)`
  align-items: center;
  margin-bottom: 32px;
`;
export const ModalBlock = styled(FlexColumnStart)`
  background: ${colors.gray900};
  box-sizing: border-box;
  justify-content: flex-start;
  box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
  border-radius: 10px;
  padding: 16px;
  min-width: 800px;
  margin-top: 50px;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    min-width: auto;
    width: calc(100vw - 30px);
  }
`;

export const ModalTitle = styled.p`
  font-size: 20px;
  font-weight: 600;
  margin: 0px;
`;
export const Close = styled(FlexColumn)`
  width: 16px;
  height: 16px;
  cursor: pointer;
  filter: invert(1);
  img {
    width: 16px;
    height: 16px;
  }
`;
export const Page = styled(FlexColumn)`
  width: calc(100% - 30px);
  padding-bottom: 100px;
`;
export const StyledButton = styled.button`
  width: 100%;
  font-size: 16px;
  font-weight: 300;
  cursor: pointer;
  height: 46px;
  background: ${colors.primary600};
  border-radius: 6px;
  color: #fff;
  border: none;

  &:hover {
    opacity: 0.9;
  }
`;

interface ActionButtonProps {
    variant?: "danger" | "warning";
}

export const ActionButton = styled.button<ActionButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 300;
  cursor: pointer;
  height: 30px;
  background: ${({variant}) => variant === "danger" && colors.danger600
          || variant === "warning"  && colors.warning600
          || colors.primary600};
  border-radius: 6px;
  color: #fff;
  border: none;
  padding: 0 12px;

  &:hover {
    opacity: 0.9;
  }
`;

interface SelectButtonProps {
    selected?: boolean;
}

export const SelectButton = styled.button<SelectButtonProps>`
  font-size: 14px;
  font-weight: 300;
  cursor: pointer;
  height: 30px;
  background: ${({selected}) => selected ? colors.primary600 : "none"};
  color: ${({selected}) => selected ? "#fff" : colors.primary600};
  border: 1px solid ${colors.primary600};
  border-radius: 6px;
  padding: 0 12px;

  &:hover {
    opacity: 0.9;
  }
`;

export const Table = styled.table`
  font-size: 14px;
  text-align: left;
  width: 100%;
  border-collapse: collapse;

  thead {
    font-weight: 500;
    background: ${colors.gray800};
  }

  th {
    padding: 10px;
    border-bottom: 0.5px solid ${colors.gray800};
  }

  td {
    padding: 10px;
    background: ${colors.gray900};
  }
`;

export const Label = styled.p`
  font-size: 15px;
  font-weight: 400;
  margin: 0;
  width: 100%;
`;

type IErrorTextProps = {
    hasError?: boolean
}
export const ErrorText = styled.p<IErrorTextProps>`
  color: ${colors.redFail};
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  width: 100%;
  min-height: 14px;
  display: ${props => props.hasError ? 'block' : 'none'};
  margin-top: 10px;
  margin-bottom: 5px;
`;

export const InputWrapper = styled(FlexRow)`
  position: relative;
  align-items: start;
  width: 100%;

  label {
    left: 20px;
    right: 0;
  }
`;

export const PassIcon = styled.img`
  position: absolute;
  cursor: pointer;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
`;