import React from "react";
import {FlexColumn, FlexRow, Label, SelectButton} from "./global/global";
import styled from "styled-components";


const Block = styled(FlexColumn)`
  align-items: start;
  width: 100%;
  gap: 8px;
`

interface AddressComponentProps {
    title: string;
    elements: any[];
    selectedId: number;
    selectElement: (id: number) => void;
    isPool?: boolean;
}

const SelectionPanel: React.FC<AddressComponentProps> = ({title, elements, selectedId, selectElement, isPool}) => {

    const renderElementTitle = (el: any) => {
        if (typeof el === 'string') {
            return el;
        }
        return el.name || 'Unknown';
    };

    return (
        <Block>
            <Label>{title}</Label>
            <FlexRow style={{gap: 8}}>
                {elements.map((el, id) =>
                    <SelectButton
                        key={id}
                        onClick={(e) => {
                            e.preventDefault()
                            selectElement(isPool ? el.id : id)
                        }}
                        selected={selectedId === (isPool ? el.id : id)}>
                        {renderElementTitle(el)}
                    </SelectButton>)}
            </FlexRow>
        </Block>
    );
}



export default SelectionPanel