import React from "react";
import {
    Area,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    AreaChart,
} from 'recharts';
import styled from "styled-components";
import {FlexColumn} from "../global";
import {colors} from "../../../config/colors";

const ChartWrapper = styled(FlexColumn)`
  width: 100%;
  height: 300px;

  line {
    stroke: ${colors.gray};
  }
`;

interface IArea {
    dataKey: string,
    color: string
}

interface LineBarAreaComposedChartProps {
    cycles: any,
    dataKey: string,
    areas: IArea[]
}

const StakedAreaChart: React.FC<LineBarAreaComposedChartProps> = ({cycles, dataKey, areas}) => {

    return (
        <ChartWrapper>
            <ResponsiveContainer width="100%" height="100%">
                <AreaChart width={500} height={350} data={cycles}>
                    <CartesianGrid strokeDasharray="3 3"/>
                    <XAxis dataKey={dataKey} tick={{fontSize: 15}}/>
                    <YAxis fontSize={12}/>
                    <Tooltip contentStyle={{fontSize: 14, textAlign: "left"}}/>
                    {areas.map((area) => <Area type="monotone"
                                               dataKey={area.dataKey}
                                               stackId="1"
                                               stroke={area.color}
                                               fill={area.color}/>)}
                </AreaChart>
            </ResponsiveContainer>
        </ChartWrapper>
    );
};


export default StakedAreaChart;