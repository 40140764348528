import {
    IGetBotDailyHistory,
    IGetBotHistoryRequest, IGetBotHistoryResponse, IGetDailyHistoryRequest,
    IGetStatsDailyGeneralResponse, IGetStatsDailyTokensResponse,
    IGetStatsRequest, IGetStatsTokenPriceResponse,
    IStatsInterface
} from "./models";
import {apiClient} from "../serverConstants";

class StatsService implements IStatsInterface {
    async dailyGeneral(data: IGetStatsRequest) {
        const response = await apiClient.get('/statistics/daily/general', {params: data});
        return response.data as IGetStatsDailyGeneralResponse;
    }

    async dailyTokens(data: IGetStatsRequest) {
        const response = await apiClient.get('/statistics/daily/tokens', {params: data});
        return response.data as IGetStatsDailyTokensResponse;
    }

    async dailyTokenPrice(data: IGetStatsRequest) {
        const response = await apiClient.get('/statistics/token-pair-price', {params: data});
        return response.data as IGetStatsTokenPriceResponse;
    }

    async botHistory(data: IGetBotHistoryRequest) {
        const response = await apiClient.get('/statistics/cycle/history', {params: data});
        return response.data as IGetBotHistoryResponse;
    }

    async dailyHistory(data: IGetDailyHistoryRequest) {
        const response = await apiClient.get('/statistics/daily/history', {params: data});
        return response.data as IGetBotDailyHistory[];
    }
}

export default new StatsService();