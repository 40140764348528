export const truncate = (value: string): string => {
    const maxIndexByFirstHalf = Math.trunc(value.length * 0.17)
    const minIndexByLastHalf = value.length - Math.trunc(value.length * 0.12)
    return `${value.slice(0, maxIndexByFirstHalf)}...${value.slice(minIndexByLastHalf, value.length)}`
}

export const truncateString = (str: string | any[], maxLength: number): string => {
    if (str.length <= maxLength) { // @ts-ignore
        return str;
    }
    const start = str.slice(0, maxLength / 2 - 1);
    const end = str.slice(str.length - maxLength / 2 + 1);
    return `${start}...${end}`;
}