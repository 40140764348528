import {
    IWalletsAddPoolRequest,
    IWalletsAddWalletRequest, IWalletsEditPoolRequest, IWalletsGetPoolsResponse,
    IWalletsGetRequest,
    IWalletsGetResponse,
    IWalletsInterface, IWalletsUpdateRequest,
} from "./models";
import {apiClient} from "../serverConstants";

class WalletsService implements IWalletsInterface {
    async addWallet(data: IWalletsAddWalletRequest) {
        const response = await apiClient.post('/wallets', data);
        return response.data;
    }
    async removeWallet(data: IWalletsUpdateRequest) {
        const response = await apiClient.delete('/wallets', {data});
        return response.data;
    }
    async getWallets(data: IWalletsGetRequest) {
        const response = await apiClient.get('/wallets', {params: data});
        return response.data as IWalletsGetResponse;
    }

    async addPool(data: IWalletsAddPoolRequest) {
        const response = await apiClient.post('/wallets/pools', data);
        return response.data;
    }
    async editPool(data: IWalletsEditPoolRequest) {
        const response = await apiClient.post('/wallets/pools/wallets', data);
        return response.data;
    }
    async removePool(data: IWalletsUpdateRequest) {
        const response = await apiClient.delete('/wallets/pools', {data});
        return response.data;
    }
    async getPools(data: IWalletsGetRequest) {
        const response = await apiClient.get('/wallets/pools', {params: data});
        return response.data as IWalletsGetPoolsResponse;
    }
}

export default new WalletsService();