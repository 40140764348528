import BigNumber from 'bignumber.js'

export const convertNumberToEth = (value: string | number, decimals?: number) => {
    return new BigNumber(value).times(new BigNumber(10).pow(decimals || 18)).dividedBy(BIG_TEN.pow(0)).toFixed();
}

export const BIG_TEN = new BigNumber(10)
export const BIG_ZERO = new BigNumber(0)
export const DEFAULT_TOKEN_DECIMAL = new BigNumber(10).pow(18)

export const getBalanceNumber = (balance: BigNumber, decimals = 18) => {
    const displayBalance = new BigNumber(balance).dividedBy(BIG_TEN.pow(decimals))
    return displayBalance.toNumber()
}

export const getFullDisplayBalance = (balance: BigNumber, decimals = 18) => {
    const newNumber = new BigNumber(balance);
    const divisor = new BigNumber(10).pow(decimals);
    return newNumber.dividedBy(divisor).toFixed();
}