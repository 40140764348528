import React from "react";
import styled from "styled-components";
import {colors} from "../../../config/colors";
import {ActionButton} from "../../global/global";
import {INetworkGas} from "../../../api/service/models";
import {getBalanceNumber} from "../../../utils/convertNumberToEth";
import BigNumber from "bignumber.js";

const TableRow = styled.tr`
`
const TableData = styled.td`
  border-bottom: 0.5px solid ${colors.gray800};
`

interface WalletComponentProps {
    id: number;
    address: string;
    removeWallet: (id: number) => void;
    gas: INetworkGas
}

const WalletComponent: React.FC<WalletComponentProps> = ({id, address, removeWallet, gas}) => {

    return (
        <TableRow>
            <TableData>{id}</TableData>
            <TableData>{address}</TableData>
            <TableData>{getBalanceNumber(new BigNumber(gas.ETH))}</TableData>
            <TableData>{getBalanceNumber(new BigNumber(gas.BSC))}</TableData>
            <TableData>{getBalanceNumber(new BigNumber(gas.Polygon))}</TableData>
            <TableData>{getBalanceNumber(new BigNumber(gas.Base))}</TableData>
            <TableData>{getBalanceNumber(new BigNumber(gas.Optimism))}</TableData>
            <TableData>{getBalanceNumber(new BigNumber(gas.Arbitrum))}</TableData>
            <TableData>
                <ActionButton variant="danger" onClick={() => removeWallet(id)}>
                    Delete
                </ActionButton>
            </TableData>
        </TableRow>
    );
};


export default WalletComponent;