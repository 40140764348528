import React, {useContext, useState} from "react";
import {ErrorText, FlexColumn, FlexColumnStart, InputWrapper, Modal, ModalBlock, ModalTitle} from "../global/global";
import styled from "styled-components";
import ModalHeaderComponent from "./components/ModalHeaderComponent";
import {ModalButton} from "./components/components";
import {
    IBot,
    IBotEditRequest,
    INetworkType,
    IPool,
    IPoolFee,
    IStrategyType, ISwapService
} from "../../api/service/models";
import GlobalModalStore from "../../storage/GlobalModalStore/GlobalModalStore";
import {useMutation, useQueryClient} from "react-query";
import BotsApi from "../../api/service/BotsApi";
import {DEFAULT_NETWORKS} from "../../config/networks";
import SelectionPanel from "../SelectionPanel";
import {strategies} from "../../config/strategies";
import {poolFees} from "../../config/poolFees";
import {isValidEthereumAddress} from "../../utils/validateEthAddress";
import {convertNumberToEth, getFullDisplayBalance} from "../../utils/convertNumberToEth";
import BigNumber from "bignumber.js";
import {Controller, useForm} from "react-hook-form";
import {ValidationInputComponent} from "../global/ValidationInputComponent";
import {protocols} from "../../config/protocols";

const InputsPanel = styled(FlexColumn)`
  gap: 12px;
  width: 100%;
  margin-bottom: 16px;
`;

interface AddBotModalProps {
    bot: IBot;
    pools: IPool[];
    openMenu: boolean;
    handleMenu: () => void;
}

const EditBotModal: React.FC<AddBotModalProps> = ({
                                                      pools,
                                                      openMenu,
                                                      handleMenu,
                                                      bot
                                                  }) => {

    const {
        control,
        clearErrors,
        register,
        watch,
        setError,
        handleSubmit,
        formState: {errors},
        getValues,
        reset
    } = useForm({
        defaultValues: {
            name: bot.name || "",
            mainTokenAddress: bot.mainToken.address || "",
            quoteTokenAddress: bot.quoteToken.address || "",
            dealsPerDay: String(bot.strategy.dealsPerDay) || "0",
            volumeMin: getFullDisplayBalance(new BigNumber(bot.strategy.minDayVolumeInToken), bot.mainToken.decimals)  || "0",
            volumeMax: getFullDisplayBalance(new BigNumber(bot.strategy.maxDayVolumeInToken), bot.mainToken.decimals)  || "0",
            dealMin: getFullDisplayBalance(new BigNumber(bot.strategy.minDealVolumeInToken), bot.mainToken.decimals)  || "0",
            dealMax: getFullDisplayBalance(new BigNumber(bot.strategy.maxDealVolumeInToken), bot.mainToken.decimals) || "0",
            minSignificantDigits: String(bot.strategy.minSignificantDigits) || "0",
            maxSignificantDigits: String(bot.strategy.maxSignificantDigits) || "0",
            mainTokenDecimals: bot?.mainToken?.decimals?.toString() || ""
        }
    });

    const [networkId, setNetworkId] = useState(DEFAULT_NETWORKS.findIndex(network => network.backendName === bot.network));
    const [protocolId, setProtocolId] = useState(protocols.findIndex(protocol => protocol === bot.swapService));
    const [strategyId, setStrategyId] = useState(strategies.findIndex(strat => strat === bot.strategy.strategyType));
    const [commissionId, setCommissionId] = useState(poolFees.findIndex(p => p.fee === bot.poolFee));
    const [poolId, setPoolId] = useState(bot.walletPool.id);


    const GlobalModalStorage = useContext(GlobalModalStore);
    const queryClient = useQueryClient();

    const editBotMutation = useMutation((data: IBotEditRequest) => BotsApi.editBot(data), {
        onError: error => {
            GlobalModalStorage.makeVisible(false, "Бот не обновлен");
        },
        onSuccess: data => {
            handleMenu();
            GlobalModalStorage.makeVisible(true, "Бот обновлен");
            queryClient.invalidateQueries('get_bots');
        },
    });

    const onSubmit = (data) => {
        const newData = {
            botId: bot.id,
            name: data.name,
            network: DEFAULT_NETWORKS[networkId].backendName as INetworkType,
            swapService: protocols[protocolId] as ISwapService,
            mainTokenAddress: data.mainTokenAddress,
            quoteTokenAddress: data.quoteTokenAddress,
            poolFee: poolFees[commissionId].feeString as IPoolFee,
            strategyType: strategies[strategyId] as IStrategyType,
            dealsPerDay: data.dealsPerDay,
            minDealVolumeInToken: convertNumberToEth(data.dealMin, parseInt(data.mainTokenDecimals)).toString(),
            maxDealVolumeInToken: convertNumberToEth(data.dealMax, parseInt(data.mainTokenDecimals)).toString(),
            minDayVolumeInToken: convertNumberToEth(data.volumeMin, parseInt(data.mainTokenDecimals)).toString(),
            maxDayVolumeInToken: convertNumberToEth(data.volumeMax, parseInt(data.mainTokenDecimals)).toString(),
            minSignificantDigits: data.minSignificantDigits,
            maxSignificantDigits: data.maxSignificantDigits,
            walletPoolId: poolId
        };
        editBotMutation.mutate(newData);
        reset();
    };

    if (openMenu) return (
        <Modal>
            <form onSubmit={handleSubmit(onSubmit)}>
                <ModalBlock>
                    <ModalHeaderComponent title="Edit bot" handleMenu={handleMenu}/>
                    <ModalTitle style={{marginBottom: 16, marginTop: 16}}>Bot settings</ModalTitle>
                    <InputsPanel>
                        <FlexColumnStart fz>
                            <InputWrapper>
                                <Controller
                                    name="name"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'Need to fill in the name of the bot'
                                        }
                                    }}
                                    render={({field: {ref, ...field}}) =>
                                        <ValidationInputComponent
                                            {...field}
                                            isError={!!errors.name}
                                            label="Bot name"/>}
                                />
                            </InputWrapper>
                            <ErrorText hasError={!!errors.name}>
                                Need to fill in the name of the bot
                            </ErrorText>
                        </FlexColumnStart>
                        <SelectionPanel title="Pool"
                                        isPool
                                        elements={pools}
                                        selectedId={poolId}
                                        selectElement={setPoolId}/>
                        <SelectionPanel title="Chain"
                                        elements={DEFAULT_NETWORKS.map((network) => network.symbol)}
                                        selectedId={networkId}
                                        selectElement={setNetworkId}/>
                        <SelectionPanel title="Protocol"
                                        elements={protocols}
                                        selectedId={protocolId}
                                        selectElement={setProtocolId}/>
                        <SelectionPanel title="Strategy"
                                        elements={strategies}
                                        selectedId={strategyId}
                                        selectElement={setStrategyId}/>
                        <SelectionPanel title="Pool commission"
                                        elements={poolFees.map((p) => p.title)}
                                        selectedId={commissionId}
                                        selectElement={setCommissionId}/>
                        <FlexColumnStart fz style={{marginTop: 24}}>
                            <InputWrapper>
                                <Controller
                                    name="mainTokenAddress"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'Требуется ввести адрес Main токена'
                                        },
                                        validate: isValidEthereumAddress
                                    }}
                                    render={({field: {ref, ...field}}) =>
                                        <ValidationInputComponent
                                            {...field}
                                            isError={!!errors.mainTokenAddress}
                                            label="Main token"/>}
                                />
                            </InputWrapper>
                            <ErrorText hasError={!!errors.mainTokenAddress}>
                                Требуется заполнить коректный адрес Main токена
                            </ErrorText>
                        </FlexColumnStart>
                        <FlexColumnStart fz>
                            <InputWrapper>
                                <Controller
                                    name="mainTokenDecimals"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'Требуется ввести decimals Main токена'
                                        },
                                        validate: value => parseFloat(value) > 0
                                    }}
                                    render={({field: {ref, ...field}}) =>
                                        <ValidationInputComponent
                                            {...field}
                                            isError={!!errors.mainTokenDecimals}
                                            label="Main Decimals"/>}
                                />
                            </InputWrapper>
                            <ErrorText hasError={!!errors.mainTokenDecimals}>
                                Требуется заполнить Decimals Main токена
                            </ErrorText>
                        </FlexColumnStart>
                        <FlexColumnStart fz>
                            <InputWrapper>
                                <Controller
                                    name="quoteTokenAddress"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'Требуется ввести адрес Quote токена'
                                        },
                                        validate: isValidEthereumAddress
                                    }}
                                    render={({field: {ref, ...field}}) =>
                                        <ValidationInputComponent
                                            {...field}
                                            isError={!!errors.quoteTokenAddress}
                                            label="Quote token"/>}
                                />
                            </InputWrapper>
                            <ErrorText hasError={!!errors.quoteTokenAddress}>
                                Требуется заполнить коректный адрес Quote токена
                            </ErrorText>
                        </FlexColumnStart>
                        <FlexColumnStart fz>
                            <InputWrapper>
                                <Controller
                                    name="dealsPerDay"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'Требуется ввести количество токенов в день'
                                        },
                                        validate: value => parseFloat(value) > 0
                                    }}
                                    render={({field: {ref, ...field}}) =>
                                        <ValidationInputComponent
                                            {...field}
                                            type="number"
                                            isError={!!errors.dealsPerDay}
                                            label="Trades per day"/>}
                                />
                            </InputWrapper>
                            <ErrorText hasError={!!errors.dealsPerDay}>
                                Требуется заполнить количество сделок в день
                            </ErrorText>
                        </FlexColumnStart>
                        <FlexColumnStart fz>
                            <InputWrapper>
                                <Controller
                                    name="volumeMin"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'Требуется ввести объем main токена в день min'
                                        },
                                        validate: value => parseFloat(value) > 0
                                    }}
                                    render={({field: {ref, ...field}}) =>
                                        <ValidationInputComponent
                                            {...field}
                                            type="number"
                                            isError={!!errors.volumeMin}
                                            label="Объем main токена в день min"/>}
                                />
                            </InputWrapper>
                            <ErrorText hasError={!!errors.volumeMin}>
                                Необходимо заполнить требуемый Min объем в день
                            </ErrorText>
                        </FlexColumnStart>
                        <FlexColumnStart fz>
                            <InputWrapper>
                                <Controller
                                    name="volumeMax"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'Требуется ввести объем main токена в день max'
                                        },
                                        validate: value => parseFloat(value) > 0
                                    }}
                                    render={({field: {ref, ...field}}) =>
                                        <ValidationInputComponent
                                            {...field}
                                            type="number"
                                            isError={!!errors.volumeMax}
                                            label="Объем main токена в день max"/>}
                                />
                            </InputWrapper>
                            <ErrorText hasError={!!errors.volumeMax}>
                                Необходимо заполнить требуемый Max объем в день
                            </ErrorText>
                        </FlexColumnStart>
                        <FlexColumnStart fz>
                            <InputWrapper>
                                <Controller
                                    name="dealMin"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'Требуется ввести объем 1 сделки в main токене min'
                                        },
                                        validate: value => parseFloat(value) > 0
                                    }}
                                    render={({field: {ref, ...field}}) =>
                                        <ValidationInputComponent
                                            {...field}
                                            type="number"
                                            isError={!!errors.dealMin}
                                            label="Объем 1 сделки в main токене min"/>}
                                />
                            </InputWrapper>
                            <ErrorText hasError={!!errors.dealMin}>
                                Требуется заполнить объем 1 сделки в main токене min
                            </ErrorText>
                        </FlexColumnStart>
                        <FlexColumnStart fz>
                            <InputWrapper>
                                <Controller
                                    name="dealMax"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'Требуется ввести объем 1 сделки в main токене max'
                                        },
                                        validate: value => parseFloat(value) > 0
                                    }}
                                    render={({field: {ref, ...field}}) =>
                                        <ValidationInputComponent
                                            {...field}
                                            type="number"
                                            isError={!!errors.dealMax}
                                            label="Объем 1 сделки в main токене max"/>}
                                />
                            </InputWrapper>
                            <ErrorText hasError={!!errors.dealMax}>
                                Требуется заполнить объем 1 сделки в main токене max
                            </ErrorText>
                        </FlexColumnStart>
                        <FlexColumnStart fz>
                            <InputWrapper>
                                <Controller
                                    name="minSignificantDigits"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'Требуется ввести Min количество знаков для округления'
                                        }
                                    }}
                                    render={({field: {ref, ...field}}) =>
                                        <ValidationInputComponent
                                            {...field}
                                            type="number"
                                            isError={!!errors.minSignificantDigits}
                                            label="Min количество знаков для округления"/>}
                                />
                            </InputWrapper>
                            <ErrorText hasError={!!errors.minSignificantDigits}>
                                Требуется заполнить Min количество знаков для округления
                            </ErrorText>
                        </FlexColumnStart>
                        <FlexColumnStart fz>
                            <InputWrapper>
                                <Controller
                                    name="maxSignificantDigits"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'Требуется ввести Max количество знаков для округления'
                                        }
                                    }}
                                    render={({field: {ref, ...field}}) =>
                                        <ValidationInputComponent
                                            {...field}
                                            type="number"
                                            isError={!!errors.maxSignificantDigits}
                                            label="Max количество знаков для округления"/>}
                                />
                            </InputWrapper>
                            <ErrorText hasError={!!errors.maxSignificantDigits}>
                                Требуется заполнить Max количество знаков для округления
                            </ErrorText>
                        </FlexColumnStart>
                    </InputsPanel>
                    <ModalButton type="submit" onClick={() => clearErrors()} disabled={editBotMutation.isLoading}>
                        Изменить
                    </ModalButton>
                </ModalBlock>
            </form>
        </Modal>
    );
};


export default EditBotModal;
