export const toggleElementById = (id: number, selectedIds: number[], setSelectedIds: any) => {
    if (selectedIds.includes(id)) {
        const newArray = selectedIds.filter(item => item !== id);
        setSelectedIds(newArray);
    } else {
        const newArray = [...selectedIds, id];
        setSelectedIds(newArray);
    }
};

export const toggleAllElementsById = (elements: any[], selectedIds: number[], setSelectedIds: any) => {
    if (elements.length === selectedIds.length) {
        setSelectedIds([]);
    } else {
        const newArray = elements.map((i, id) => id);
        setSelectedIds(newArray);
    }
};

export const toggleFormElementById = (elId, fields, remove, append) => {
    const fieldIndex = fields.findIndex((field) => field.element === elId);
    if (fieldIndex >= 0) {
        remove(fieldIndex);
    } else {
        append({element: elId});
    }
};
export const isIdInForm = (idToCheck, fields) => {
    return fields.some((field) => field.element === idToCheck);
};
