import React from "react";
import {IBotStatus} from "../../../api/service/models";
import styled from "styled-components";
import {FlexRow} from "../../global/global";
import {colors} from "../../../config/colors";

interface BlockProps {
    color: string;
}
const Block = styled(FlexRow)<BlockProps>`
  border: 1px solid ${({color}) => color};
  width: 20px;
  height: 20px;
  border-radius: 100%;
`
const ActiveElement = styled.div<BlockProps>`
  width: 17px;
  height: 17px;
  border-radius: 100%;
  background: ${({color}) => color};
  animation: ${({color}) => color === colors.redFail ? "none" : "blink 1.2s infinite"};

  @keyframes blink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`

interface BotStatusProps {
    botStatus: IBotStatus;
}

const BotStatus: React.FC<BotStatusProps> = ({botStatus}) => {

    const color = botStatus === IBotStatus.Stopped && colors.redFail || colors.greenSuccess

    return (
        <Block color={color}>
            <ActiveElement color={color}/>
        </Block>
    );
};


export default BotStatus;