import React from "react";
import styled from "styled-components";
import {colors} from "../config/colors";

const Block = styled.div`
  background: ${colors.gray900};
  border-radius: 10px;
  padding: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 10px;
  height: 80px;
  width: 300px;
  box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
  @media (max-width: 768px) {
    width: 100%;
  }
`;
const Value = styled.h1`
  font-weight: 400;
  font-size: 36px;
  margin: 0;
  margin-bottom: 10px;
`;

const Title = styled.p`
  font-size: 16px;
  margin: 0;
  opacity: 0.8;
  text-align: center;
`;

interface StatsComponentProps {
    value: string;
    title: string;
}

const StatsComponent: React.FC<StatsComponentProps> = ({value, title}) => {

    return (
        <Block>
            <Value>{value}</Value>
            <Title>{title}</Title>
        </Block>
    );
};


export default StatsComponent;