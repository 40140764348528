import React, {useContext} from "react";
import {ErrorText, FlexColumn, FlexColumnStart, InputWrapper, Modal, ModalBlock, ModalTitle} from "../global/global";
import styled from "styled-components";
import ModalHeaderComponent from "./components/ModalHeaderComponent";
import {ModalButton} from "./components/components";
import {IWallet, IWalletsAddPoolRequest, IWalletsEditPoolRequest} from "../../api/service/models";
import WalletWithBalances from "../WalletWithBalances";
import {isIdInForm, toggleFormElementById} from "../../utils/toggleElementById";
import GlobalModalStore from "../../storage/GlobalModalStore/GlobalModalStore";
import {useMutation, useQueryClient} from "react-query";
import WalletsApi from "../../api/service/WalletsApi";
import {Controller, useFieldArray, useForm} from "react-hook-form";
import {ValidationInputComponent} from "../global/ValidationInputComponent";


const AddressList = styled(FlexColumn)`
  justify-content: start;
  align-items: start;
  gap: 8px;
`;

interface AddWalletsPoolModalProps {
    openMenu: boolean;
    handleMenu: () => void;
    wallets: IWallet[];
    poolWallets?: IWallet[];
    isEdit?: boolean;
    poolId?: number;
}

// @ts-ignore
const AddPoolModal: React.FC<AddWalletsPoolModalProps> = ({
                                                                     openMenu,
                                                                     handleMenu,
                                                                     wallets,
                                                                     poolWallets,
                                                                     isEdit,
                                                                     poolId
                                                                 }) => {

    const {
        control,
        clearErrors,
        register,
        watch,
        setError,
        handleSubmit,
        formState: {errors},
        getValues,
        reset
    } = useForm({
        defaultValues: {
            name: "",
            walletIds: (isEdit && poolWallets ? poolWallets.map((p) => ({ element: p.id })) : []) || []
        }
    });

    const { append, remove, fields } = useFieldArray({
        name: "walletIds",
        control: control
    });

    const toggleWallet = (walletId) => {
        toggleFormElementById(walletId, fields, remove, append)
    };

    const isIdInFields = (idToCheck): boolean => {
       return isIdInForm(idToCheck, fields)
    };

    const GlobalModalStorage = useContext(GlobalModalStore);
    const queryClient = useQueryClient();

    const addPoolMutation = useMutation((data: IWalletsAddPoolRequest) => WalletsApi.addPool(data), {
        onError: error => {
            GlobalModalStorage.makeVisible(false, "Пул не создан");
        },
        onSuccess: data => {
            handleMenu();
            GlobalModalStorage.makeVisible(true, "Пул создан");
            queryClient.invalidateQueries('get_pools');
        },
    });

    const editPoolMutation = useMutation((data: IWalletsEditPoolRequest) => WalletsApi.editPool(data), {
        onError: error => {
            GlobalModalStorage.makeVisible(false, "Пул не обновлен");
        },
        onSuccess: data => {
            handleMenu();
            GlobalModalStorage.makeVisible(true, "Пул обновлен");
            queryClient.invalidateQueries('get_pools');
        },
    });

    const onSubmit = (data) => {
        const walletIds = data.walletIds.map(item => item.element);
        const addData = { ...data, walletIds };
        const editData = {
            walletPoolId: poolId,
            walletIds: walletIds
        };
        if(!isEdit) {
            addPoolMutation.mutate(addData);
        } else editPoolMutation.mutate(editData)
        reset()
    };

    if (openMenu) return (
            <Modal>
                <form onSubmit={handleSubmit(onSubmit)}>
                <ModalBlock>
                    <ModalHeaderComponent title={isEdit ? "Edit pool" : "Create Pool"} handleMenu={handleMenu}/>
                    {!isEdit && <FlexColumnStart fz>
                        <InputWrapper>
                            <Controller
                                name="name"
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'Требуется заполнить название пула'
                                    }
                                }}
                                render={({field: {ref, ...field}}) =>
                                    <ValidationInputComponent
                                        {...field}
                                        onKeyDown={(e) => {
                                            if (e.code === 'Enter') {
                                                e.preventDefault();
                                                onSubmit(getValues());
                                            }
                                        }}
                                        isError={!!errors.name}
                                        label="Название пула"/>}
                            />
                        </InputWrapper>
                        <ErrorText hasError={!!errors.name}>
                            Требуется заполнить название пула
                        </ErrorText>
                    </FlexColumnStart>}
                    <ModalTitle style={{marginBottom: 16, marginTop: 16}}>Выбор кошельков</ModalTitle>
                    <AddressList style={{width: "100%"}}>
                        {wallets.map((wallet) => <WalletWithBalances
                            id={wallet.id}
                            address={wallet.address}
                            selected={isIdInFields(wallet.id)}
                            addWallet={toggleWallet}
                        />)}
                    </AddressList>
                    {wallets.length > 0 && <ModalButton type="submit" onClick={() => clearErrors()}>
                        {isEdit ? "Сохранить" : "Создать"}
                    </ModalButton>}
                </ModalBlock>
                </form>
            </Modal>
    );
};


export default AddPoolModal;
