import React, {useState} from "react";
import styled from "styled-components";
import {colors} from "../../../config/colors";
import {ActionButton, FlexColumn, FlexColumnEnd, FlexColumnStart, FlexRow, FlexRowBetween} from "../../global/global";
import {IBot, IPool} from "../../../api/service/models";
import EditBotModal from "../../modal/EditBotModal";
import StatsApi from "../../../api/service/StatsApi";
import {useQuery} from "react-query";
import {getBalanceNumber} from "../../../utils/convertNumberToEth";
import BotStatus from "./BotStatus";
import BigNumber from "bignumber.js";
import {CirclesWithBar} from "react-loader-spinner";
import BotChartsModal from "../../modal/BotChartsModal";

const Block = styled(FlexColumn)`
  width: 320px;
  background: ${colors.gray900};
  border-radius: 8px;
  padding: 16px 8px;
  gap: 12px;
  @media (max-width: 480px) {
    width: 100%;
  }
`;
const Text = styled.p`
  margin: 0;
  font-size: 12px;
  color: ${colors.gray400};
`;
const Value = styled.p`
  margin: 0;
  font-size: 14px;
  font-weight: 500;
`;

interface WalletComponentProps {
    bot: IBot;
    removeBot: (id: number) => void;
    toggleStartBot: (id: number) => void;
    pools: IPool[];
    isStartOrDeleteLoading: boolean;
}

const ContentLine = styled(FlexRowBetween)`
  height: 36px;
`;


const BotBlock: React.FC<WalletComponentProps> = ({
                                                      bot,
                                                      toggleStartBot,
                                                      removeBot,
                                                      pools,
                                                      isStartOrDeleteLoading
                                                  }) => {

    const [editBotMenu, setEditBotMenu] = useState(false);
    const handleEditBotMenu = () => setEditBotMenu(open => !open);


    const [stats, setStats] = useState(null);
    const [botHistory, setBotHistory] = useState(null);
    const [dailyHistory, setDailyHistory] = useState(null);
    const cycles = botHistory && botHistory?.items?.length > 0 && botHistory.items;
    const fetchGeneralStats = async (botId) => StatsApi.dailyGeneral({botId});
    const fetchTokensStats = async (botId) => StatsApi.dailyTokens({botId});
    const fetchTokenPriceStats = async (botId) => StatsApi.dailyTokenPrice({botId});

    const fetchAllData = async () => {
        let generalStats, tokensStats, tokenPriceStats;
        try {
            const generalStatsPromise = fetchGeneralStats(bot.id);
            const tokensStatsPromise = fetchTokensStats(bot.id);
            const tokenPriceStatsPromise = fetchTokenPriceStats(bot.id);
            generalStats = await generalStatsPromise;
            tokensStats = await tokensStatsPromise;
            tokenPriceStats = await tokenPriceStatsPromise;
        } catch (error) {
            return {generalStats: null, tokensStats: null, tokenPriceStats: null};
        }
        return {generalStats, tokensStats, tokenPriceStats};
    };

    const {data: allData, isLoading, refetch} = useQuery(['get_all_stats', bot.id], fetchAllData, {
        onSuccess: (data) => {
            setStats(data);
        },
    });

    const getBotHistory = useQuery('get_bot_history', () => StatsApi.botHistory({
        botId: bot.id,
        pageSize: 20,
        pageNumber: 1
    }), {
        onSuccess: data => {
            setBotHistory(data);
        }
    });

    const getPrevMonthDate = () => {
        const currentDate = new Date();
        currentDate.setMonth(currentDate.getMonth() - 1);
        return currentDate.toISOString();
    };
    const getBotDailyHistory = useQuery('get_daily_history', () => StatsApi.dailyHistory({
        botId: bot.id,
        from: getPrevMonthDate(),
        to: new Date().toISOString()
    }), {
        onSuccess: data => {
            setDailyHistory(data);
        }
    });

    const generalStats = stats && stats.generalStats;
    const tokenPriceStats = stats && stats.tokenPriceStats;
    const tokensStats = stats && stats.tokensStats;
    const mainToken = tokensStats && tokensStats.mainToken;
    const quoteToken = tokensStats && tokensStats.quoteToken;
    const mainDecimals = mainToken?.decimals || 18;
    const quoteDecimals = quoteToken?.decimals || 18;
    const tokenPrice = (tokenPriceStats?.mainTokenPriceInUSD || 0) + `(${tokenPriceStats?.quoteTokenPriceInUSD || 0})`;
    const todayMainTokens = (getBalanceNumber(tokensStats?.todayMainTokenVolume, mainDecimals) || 0).toLocaleString("en", {minimumFractionDigits: 6});
    const todayQuoteTokens = (getBalanceNumber(tokensStats?.todayQuoteTokenVolume, quoteDecimals) || 0).toLocaleString("en", {minimumFractionDigits: 6});
    const yesterdayMainTokens = (getBalanceNumber(tokensStats?.yesterdayMainTokenVolume, mainDecimals) || 0).toLocaleString("en", {minimumFractionDigits: 6});
    const yesterdayQuoteTokens = (getBalanceNumber(tokensStats?.yesterdayQuoteTokenVolume, quoteDecimals) || 0).toLocaleString("en", {minimumFractionDigits: 6});
    const volume24hMain = (getBalanceNumber(generalStats?.mainTokenSellVolume, mainDecimals) || 0).toLocaleString("en", {minimumFractionDigits: 6});
    const volume24hQuote = (getBalanceNumber(generalStats?.quoteTokenSellVolume, quoteDecimals) || 0).toLocaleString("en", {minimumFractionDigits: 6});
    const volume24hForward = (getBalanceNumber(new BigNumber(bot?.strategy?.currentForwardDealsVolume.number), bot?.strategy?.currentForwardDealsVolume.decimals) || 0).toLocaleString("en", {minimumFractionDigits: 6});
    const volume24hReverse = (getBalanceNumber(new BigNumber(bot?.strategy?.currentReverseDealsVolume.number), bot?.strategy?.currentReverseDealsVolume.decimals) || 0).toLocaleString("en", {minimumFractionDigits: 6});

    const statisticsList = [
        [
            {title: "Network", value: bot.network},
            {title: "Status", value: bot.status},
        ],
        [
            {title: "Main token", value: mainToken?.symbol},
            {title: "Quote token", value: quoteToken?.symbol},
        ],
        [
            {title: "Main 24h Vol", value: volume24hMain},
            {title: "Quote 24h Vol", value: volume24hQuote},
        ],
        [
            {title: "24h Vol", value: generalStats?.totalVolumeInUSD || 0},
            {title: "Token Price", value: tokenPrice}
        ],
        [
            {title: "24h Deals", value: bot?.strategy?.dealsPerDay || 0},
            {title: "24h Deals Count", value: bot?.strategy?.currentDealsCount || 0}
        ],
        [
            {
                title: "24h Forward Vol",
                value: volume24hForward
            },
            {
                title: "24h Reverse Vol",
                value: volume24hReverse
            }
        ],
        [
            {title: "Today Main Amount", value: todayMainTokens},
            {title: "Yesterday Main Amount", value: yesterdayMainTokens}
        ],
        [
            {title: "Today Quote Amount", value: todayQuoteTokens},
            {title: "Yesterday Quote Amount", value: yesterdayQuoteTokens}
        ],
    ];

    const editText = isStartOrDeleteLoading ? "Loading" : "Edit";
    const deleteText = isStartOrDeleteLoading ? "Loading" : "Delete";
    const startText = isStartOrDeleteLoading ? "Loading" : (bot.status === "Stopped" ? "Start bot" : "Stop bot");

    const [botChartsModal, setBotChartsModal] = useState(false);
    const handleBotChartsModal = () => setBotChartsModal(open => !open);

    return (
        <Block>
            <EditBotModal openMenu={editBotMenu} handleMenu={handleEditBotMenu} pools={pools} bot={bot}/>
            <ContentLine>
                <FlexRow style={{alignItems: "start", gap: 8}}>
                    <BotStatus botStatus={bot.status}/>
                    <FlexColumnStart>
                        <Text>ID</Text>
                        <Value>{bot.id}</Value>
                    </FlexColumnStart>
                </FlexRow>
                <FlexColumnEnd>
                    <Text>Name</Text>
                    <Value>{bot.name}</Value>
                </FlexColumnEnd>
            </ContentLine>
            {statisticsList.map((stats) => <ContentLine>
                    <FlexColumnStart>
                        <Text>{stats[0].title}</Text>
                        <Value>{stats[0].value}</Value>
                    </FlexColumnStart>
                    <FlexColumnEnd>
                        <Text>{stats[1].title}</Text>
                        <Value>{stats[1].value}</Value>
                    </FlexColumnEnd>
                </ContentLine>
            )}
            <FlexColumn style={{width: "100%"}}>
                <ContentLine>
                    <ActionButton onClick={handleBotChartsModal} disabled={isStartOrDeleteLoading}>
                        View Stats
                    </ActionButton>
                </ContentLine>
                <ContentLine>
                    <ActionButton style={{background: bot.status === "Stopped" ? colors.greenSuccess : colors.redFail}}
                                  onClick={() => toggleStartBot(bot.id)}
                                  disabled={isStartOrDeleteLoading}>
                        <p style={{marginRight: 6}}>
                            {startText}
                        </p>
                        {isStartOrDeleteLoading && <CirclesWithBar
                            height="18"
                            width="18"
                            color="#fff"
                            outerCircleColor="#fff"
                            innerCircleColor="#fff"
                            barColor="#fff"
                            ariaLabel="circles-with-bar-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                        />}
                    </ActionButton>
                    <FlexRow style={{gap: 6}}>
                        <ActionButton style={{background: colors.redFail}} onClick={() => removeBot(bot.id)}
                                      disabled={isStartOrDeleteLoading}>
                            {deleteText}
                        </ActionButton>
                        <ActionButton onClick={handleEditBotMenu} disabled={isStartOrDeleteLoading}>
                            {editText}
                        </ActionButton>
                    </FlexRow>
                </ContentLine>
            </FlexColumn>
            <BotChartsModal cycles={cycles}
                            dailyHistory={dailyHistory}
                            openMenu={botChartsModal}
                            handleMenu={handleBotChartsModal}
                            mainToken={mainToken?.symbol || ""}/>
        </Block>
    );
};


export default BotBlock;