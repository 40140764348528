import {IAuthInterface, IAuthSigninRequest, ITokens} from "./models";
import {apiClient} from "../serverConstants";

class AuthService implements IAuthInterface {
    async refreshToken(data: ITokens) {
        const response = await apiClient.post('/auth/refresh-token', data);
        return response.data as ITokens;
    }
    async signin(data: IAuthSigninRequest) {
        const response = await apiClient.post('/auth/signin', data);
        return response.data as ITokens;
    }
}

export default new AuthService();