import React, {useContext, useState} from 'react';
import {
    ErrorText,
    FlexColumn,
    FlexColumnStart,
    InputWrapper,
    Page, PassIcon,
    StyledButton
} from "../components/global/global";
import styled from "styled-components";
import {useMutation} from "react-query";
import {IAuthSigninRequest} from "../api/service/models";
import AuthApi from "../api/service/AuthApi";
import UserStorage from "../storage/UserStorage/UserStorage";
import {useNavigate} from "react-router-dom";
import GlobalModalStore from "../storage/GlobalModalStore/GlobalModalStore";
import {jwtDecode} from "jwt-decode";
import {observer} from "mobx-react-lite";
import {Controller, useForm} from "react-hook-form";
import {ValidationInputComponent} from '../components/global/ValidationInputComponent';
import {colors} from "../config/colors";


const Block = styled(FlexColumn)`
  background: ${colors.gray900};
  box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
  padding: 30px;
  border-radius: 10px;
  gap: 12px;
`;

export const AuthPage: React.FC = () => {

    const navigate = useNavigate();
    const UserStore = useContext(UserStorage);
    const GlobalModalStorage = useContext(GlobalModalStore);

    const {
        control,
        clearErrors,
        register,
        watch,
        setError,
        handleSubmit,
        formState: {errors},
        getValues
    } = useForm();

    const AuthMutation = useMutation((data: IAuthSigninRequest) => AuthApi.signin(data), {
        onError: error => {
            GlobalModalStorage.makeVisible(false, "Bad auth");
        },
        onSuccess: data => {
            console.log(data);
            UserStore.setTokens(data);
            UserStore.setUser(jwtDecode(data.accessToken));
            navigate('/');
        },
    });

    const onSubmit = (data) => {
        AuthMutation.mutate(data);
    };

    const [showPass, setShowPass] = useState(false);
    const handleCheckType = () => {
        return showPass ? 'text' : 'password';
    };

    return (
        <Page>
            <form onSubmit={handleSubmit(onSubmit)}>
            <FlexColumn style={{height: "100vh"}}>
                <Block style={{width: 320}}>
                    <FlexColumnStart fz>
                        <InputWrapper>
                            <Controller
                                name="login"
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'Требуется заполнить логин'
                                    },
                                }}
                                render={({field: {ref, ...field}}) =>
                                    <ValidationInputComponent
                                        {...field}
                                        onKeyDown={(e) => {
                                            if (e.code === 'Enter') {
                                                e.preventDefault();
                                                onSubmit(getValues());
                                            }
                                        }}
                                        isError={!!errors.login}
                                        label="Логин"/>}
                            />
                        </InputWrapper>
                        <ErrorText hasError={!!errors.login}>
                            Требуется заполнить логин
                        </ErrorText>
                    </FlexColumnStart>
                    <FlexColumnStart fz>
                        <InputWrapper>
                            <Controller
                                name="password"
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'Требуется пароль'
                                    },
                                }}
                                render={({field: {ref, ...field}}) =>
                                    <ValidationInputComponent
                                        {...field}
                                        type={handleCheckType()}
                                        isError={!!errors.password}
                                        label="Пароль"/>}
                            />
                            <PassIcon
                                onClick={() => setShowPass(prev => !prev)}
                                src={showPass ? '/buttons/show.svg' : '/buttons/hide.svg'}/>
                        </InputWrapper>
                        <ErrorText hasError={!!errors.password}>
                            Требуется заполнить пароль
                        </ErrorText>
                    </FlexColumnStart>
                    <StyledButton type={'submit'}
                                  onClick={() => clearErrors()}
                                  disabled={AuthMutation.isLoading}>Вход</StyledButton>
                </Block>
            </FlexColumn>
            </form>
        </Page>
    );
};

export default observer(AuthPage);

