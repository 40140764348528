import {Close, ModalHeader, ModalTitle} from "../../global/global";

interface ModalHeaderComponentProps {
    title: string;
    handleMenu: () => void;
}

const ModalHeaderComponent: React.FC<ModalHeaderComponentProps> = ({title, handleMenu}) => {

    return (
        <ModalHeader>
            <ModalTitle>{title}</ModalTitle>
            <Close>
                <img src="/buttons/close.png" alt="" onClick={handleMenu}/>
            </Close>
        </ModalHeader>
    );
};


export default ModalHeaderComponent;
