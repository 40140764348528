import React from "react";
import {
    FlexRowBetween,
    StyledButton,
    Title
} from "./global/global";
import styled from "styled-components";


interface BlockTitleWithButtonProps {
    title: string;
    actionTitle: string;
    action: () => void;
}

const Block = styled(FlexRowBetween)`
  margin-bottom: 24px;
`

const BlockTitleWithButton: React.FC<BlockTitleWithButtonProps> = ({title, actionTitle, action}) => {

    return (
        <Block>
            <Title style={{marginBottom: 0}}>
                {title}
            </Title>
            <StyledButton onClick={action} style={{maxWidth: 180, height: 42}}>{actionTitle}</StyledButton>
        </Block>
    );
};


export default BlockTitleWithButton;