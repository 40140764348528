import React from "react";
import {
    ComposedChart,
    Area,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
} from 'recharts';
import styled from "styled-components";
import {FlexColumn} from "../global";
import {colors} from "../../../config/colors";


const ChartWrapper = styled(FlexColumn)`
  width: 100%;
  height: 300px;

  line {
    stroke: ${colors.gray};
  }
`;

interface IBar {
    dataKey: string,
    color: string
}

interface LineBarAreaComposedChartProps {
    cycles: any
    dataKey: string,
    bars: IBar[],
    xAxisKeys: string[]
}

const LineBarAreaComposedChart: React.FC<LineBarAreaComposedChartProps> = ({cycles, dataKey, bars, xAxisKeys}) => {

    return (
        <ChartWrapper>
            <ResponsiveContainer width="100%" height="100%">
                <ComposedChart
                    width={500}
                    height={350}
                    data={cycles}
                >
                    <CartesianGrid stroke="#f5f5f5"/>
                    <XAxis dataKey={xAxisKeys[0]} tick={{fontSize: 15}}/>
                    <YAxis tick={{fontSize: 15}}/>
                    <Tooltip contentStyle={{fontSize: 14, textAlign: "left"}}/>
                    <Area type="monotone" dataKey={dataKey} fill="#5888c6" stroke="#5888c6"/>
                    {bars.map((bar) => <Bar
                        dataKey={bar.dataKey}
                        barSize={20}
                        fill={bar.color}/>)}
                </ComposedChart>
            </ResponsiveContainer>
        </ChartWrapper>
    );
};


export default LineBarAreaComposedChart;