import styled from "styled-components";
import {FC, useContext} from "react";
import {observer} from "mobx-react-lite";
import GlobalModalStore, {TToast} from "../../storage/GlobalModalStore/GlobalModalStore";
import {colors} from "../../config/colors";
import {DoneIcon} from "../../assets/DoneIcon";
import {Close} from "../../assets/Close";
import {FlexColumn, FlexColumnStart, FlexRow} from "../global/global";

const Wrapper = styled(FlexColumnStart)<{ openModal: boolean }>`
  display: ${props => props.openModal ? 'flex' : 'none'};
  width: 240px;
  box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
  border-radius: 8px;
  padding: 0.64rem 1.5rem 1.41rem;
  background-color: white;
  animation-duration: 0.5s;
  animation-name: slidein;

  @keyframes slidein {
    from {
      margin-right: -100%;
    }

    to {
      margin-right: 0%;
    }
  }
`

const ImgContainer = styled(FlexColumn)`
  background-color: #ECF1F7;
  width: var(--48, 3rem);
  height: var(--48, 3rem);
  border-radius: 100px;
`

const CloseContainer = styled(FlexRow)`
  margin-top: 5px;
  width: 100%;
  align-items: end;
  justify-content: end;
  cursor: pointer;
`

const Content = styled(FlexRow)`
  gap: 1.13rem;
  align-items: center;
`

const TextWrapper = styled(FlexColumnStart)`
  gap: 10px;
  p {
    margin: 0;
    font-size: 16px;
  }
`

const MessageModal: FC<TToast> = (props) => {
    const {id, message, isVisible, isSuccess} = props;
    const GlobalModalStorage = useContext(GlobalModalStore);

    return (
        <Wrapper openModal={isVisible}>
            <CloseContainer onClick={() => GlobalModalStorage.close(id)}>
                {Close(isSuccess ? colors.greenSuccess : colors.redFail)}
            </CloseContainer>
            <Content>
                <ImgContainer>
                    {DoneIcon(isSuccess ? colors.greenSuccess : colors.redFail)}
                </ImgContainer>
                <TextWrapper>
                    <p>{isSuccess ? "Success": "Failed"}</p>
                    <p style={{color: isSuccess ? colors.greenSuccess : colors.redFail}}>{message}</p>
                </TextWrapper>
            </Content>
        </Wrapper>
    );
};

export default observer(MessageModal);
