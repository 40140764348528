import React, {useState} from "react";
import styled from "styled-components";
import {colors} from "../../../config/colors";
import {ActionButton, FlexRow} from "../../global/global";
import AddPoolModal from "../../modal/AddPoolModal";
import {IWallet} from "../../../api/service/models";


const TableRow = styled.tr`
`
const TableData = styled.td`
  border-bottom: 0.5px solid ${colors.gray800};
`

interface WalletComponentProps {
    id: number;
    name: string;
    removePool: (id: number) => void;
    wallets: IWallet[];
    poolWallets: IWallet[];
}

const PoolComponent: React.FC<WalletComponentProps> = ({id, name, removePool, wallets, poolWallets}) => {

    const [editPoolMenu, setEditPoolMenu] = useState(false);
    const handleEditPoolMenu = () => setEditPoolMenu(open => !open);

    return (
        <>
            <AddPoolModal openMenu={editPoolMenu}
                                 handleMenu={handleEditPoolMenu}
                                 wallets={wallets}
                                 poolWallets={poolWallets}
                                 poolId={id}
                                 isEdit/>
            <TableRow>
                <TableData>{id}</TableData>
                <TableData>{name}</TableData>
                <TableData>
                    <FlexRow style={{justifyContent: "start", width: "100%", gap: 6}}>
                        <ActionButton variant="danger"
                                      onClick={() => removePool(id)}>
                            Delete
                        </ActionButton>
                        <ActionButton onClick={handleEditPoolMenu}>
                            Edit
                        </ActionButton>
                    </FlexRow>
                </TableData>
            </TableRow>
        </>
    );
};


export default PoolComponent;