import React, {useContext} from "react";
import {
    ErrorText, FlexColumnStart, InputWrapper,
    Modal,
    ModalBlock,
} from "../global/global";
import ModalHeaderComponent from "./components/ModalHeaderComponent";
import {ModalButton} from "./components/components";
import {isValidPrivateKey} from "../../utils/validateEthAddress";
import {useMutation, useQueryClient} from "react-query";
import {IWalletsAddWalletRequest} from "../../api/service/models";
import GlobalModalStore from "../../storage/GlobalModalStore/GlobalModalStore";
import WalletsApi from "../../api/service/WalletsApi";
import {Controller, useForm} from "react-hook-form";
import {ValidationInputComponent} from "../global/ValidationInputComponent";


interface AddWalletModalProps {
    openMenu: boolean;
    handleMenu: () => void;
}

const AddWalletModal: React.FC<AddWalletModalProps> = ({openMenu, handleMenu}) => {

    const {
        control,
        clearErrors,
        register,
        watch,
        setError,
        handleSubmit,
        formState: {errors},
        getValues,
        reset
    } = useForm();

    const GlobalModalStorage = useContext(GlobalModalStore);
    const queryClient = useQueryClient();

    const AddWalletMutation = useMutation((data: IWalletsAddWalletRequest) => WalletsApi.addWallet(data), {
        onError: error => {
            GlobalModalStorage.makeVisible(false, "Кошелек не добавлен");
        },
        onSuccess: data => {
            handleMenu();
            GlobalModalStorage.makeVisible(true, "Кошелек добавлен");
            queryClient.invalidateQueries('get_wallets');
        },
    });

    const onSubmit = (privateKey) => {
        AddWalletMutation.mutate(privateKey);
        reset();
    };

    if (openMenu) return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Modal>
                <ModalBlock>
                    <ModalHeaderComponent title="Добавить кошелек" handleMenu={handleMenu}/>
                    <FlexColumnStart fz>
                        <InputWrapper>
                            <Controller
                                name="walletPrivateKey"
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'Требуется заполнить приватный ключ'
                                    },
                                    validate: isValidPrivateKey
                                }}
                                render={({field: {ref, ...field}}) =>
                                    <ValidationInputComponent
                                        {...field}
                                        onKeyDown={(e) => {
                                            if (e.code === 'Enter') {
                                                e.preventDefault();
                                                onSubmit(getValues());
                                            }
                                        }}
                                        isError={!!errors.walletPrivateKey}
                                        label="Приватный ключ"/>}
                            />
                        </InputWrapper>
                        <ErrorText hasError={!!errors.walletPrivateKey}>
                            Требуется заполнить валидный приватный ключ
                        </ErrorText>
                    </FlexColumnStart>
                    <ModalButton type="submit" onClick={() => clearErrors()}>Добавить</ModalButton>
                </ModalBlock>
            </Modal>
        </form>
    );
};


export default AddWalletModal;
